import { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";

import {
    COOKIE_KEY_REFRESH_TOKEN,
    LS_KEY_REFRESH_TOKEN_UPDATED,
} from "../../../constants";

export function useCurrentRefreshToken(): [
    string | undefined,
    (newToken: string) => void,
] {
    const [currentRefreshToken, setCurrentRefreshToken] = useState<string | undefined>(
        Cookies.get(COOKIE_KEY_REFRESH_TOKEN),
    );

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === LS_KEY_REFRESH_TOKEN_UPDATED) {
                // a small request to refresh the browser's cookie storage
                // without it there will be old cookie
                document.cookie = "forceRefresh=1";
                setCurrentRefreshToken(Cookies.get(COOKIE_KEY_REFRESH_TOKEN));
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const setCurrentRefreshTokenAndNotifyOtherTabs = useCallback((newRefreshToken: string) => {
        setCurrentRefreshToken(newRefreshToken);
        Cookies.set(COOKIE_KEY_REFRESH_TOKEN, newRefreshToken, { path: "/", });
        localStorage.setItem(LS_KEY_REFRESH_TOKEN_UPDATED, `${Date.now()}`);
    }, [setCurrentRefreshToken]);

    return [
        currentRefreshToken,
        setCurrentRefreshTokenAndNotifyOtherTabs,
    ];
}
