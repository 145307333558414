import { styled } from "@material-ui/styles";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Paper, Typography } from "@material-ui/core";
import React, { useMemo } from "react";

import logo from "../../../images/paznic-logo.png";

import { Copyright } from "../../../components/Copyright";
import { useAuth } from "../context";
import { AuthState } from "../enums";

export function AuthDialog() {
    const { state, msalLogin } = useAuth();

    const stateElement = useMemo(() => {
        switch (state) {
            case AuthState.LoggedOut:
                return (<>
                    <Typography>You've been logged out securely.</Typography>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={msalLogin}
                    >
                        Back To Login
                    </StyledButton>
                </>);
            case AuthState.Error:
                return (<>
                    <Typography>Login failed.</Typography>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={msalLogin}
                    >
                        Back To Login
                    </StyledButton>
                </>);
            default:
                return (
                    <div>
                        <CircularProgress />
                    </div>
                );
        }
    }, [state, msalLogin]);

    return (
        <Dialog
            open={true}
            fullWidth={true}
            PaperComponent={StyledPaper}
        >
            <DialogTitle>
                <LogoImage src={logo} alt="Paznic logo" />
            </DialogTitle>

            <StyledDialogContent>
                {stateElement}
            </StyledDialogContent>

            <CopyrightContainer>
                <Copyright />
            </CopyrightContainer>
        </Dialog>
    );
};

const StyledPaper = styled(Paper)({
    padding: "30px 0px 20px 0px",
    maxWidth: "520px",
});

const LogoImage = styled("img")({
    display: "block",
    margin: "auto",
    width: "200px",
});

const StyledDialogContent = styled(DialogContent)({
    margin: "auto",
    marginTop: "15px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
});

const StyledButton = styled(Button)({
    minWidth: "210px"
});

const CopyrightContainer = styled("div")({
    textAlign: "center",
    fontSize: "11px",
});
