import { AjaxResult } from '../enums/ajaxResult';

export interface ApiResult<TDataType> {
    result: AjaxResult;
    data?: TDataType;
    messages?: string[];
}

export class BaseApi<TEntity> {
    protected _baseUri: string;
    protected _resourceName: string;
    protected _logout?: () => void;
    protected _enqueueSnackbar: (message: React.ReactNode) => string | number | null | undefined;

    protected get _fullBaseUri(): string {
        return `${this._baseUri}/api`;
    }
    protected get _baseResource(): string {
        return `${this._fullBaseUri}/${this._resourceName}`;
    }
    protected get _basicHeaders() {
        let headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token') != null
                    ? localStorage.getItem('token')
                    : sessionStorage.getItem('token')
                }`,
        };
        return headers;
    }

    protected get _dataUploadHeaders() {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        };
        return headers;
    }

    constructor(baseUri: string, resourceName: string, logout?: () => void, enqueueSnackbar?: any) {
        this._baseUri = baseUri;
        this._resourceName = resourceName;
        this._logout = logout;
        this._enqueueSnackbar = enqueueSnackbar;
    }

    public getAll = () => this.genericRequest({ method: 'GET' }) as Promise<ApiResult<TEntity[]>>;

    public getById = (id: number) =>
        this.genericRequest({ method: 'GET', extraPath: `${id}` }) as Promise<ApiResult<TEntity>>;

    public getForOrg = (orgId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/${this._resourceName}`
        }) as Promise<ApiResult<TEntity[]>>;

    public getForSite = (orgId: number, siteId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/sites/${siteId}/${this._resourceName}`
        }) as Promise<ApiResult<TEntity[]>>;

    public postOne = (postObject: TEntity) =>
        this.genericRequest({ method: 'POST', data: postObject });

    public delete = (id: number) => this.genericRequest({ method: 'DELETE', extraPath: `${id}` });

    protected genericRequest = (options: {
        method: 'GET' | 'POST' | 'PUT' | 'DELETE';
        data?: any
        extraPath?: string;
        overridePath?: string;
        abortController?: AbortController;
        sendDataRaw?: boolean;
    }): Promise<ApiResult<any>> => {
        let { method, data, extraPath, overridePath, abortController, sendDataRaw } = options;

        let requestOptions: RequestInit = {
            method: method,
            body: (sendDataRaw ? data : JSON.stringify(data)),
            headers: (sendDataRaw ? this._dataUploadHeaders : this._basicHeaders),
            signal: abortController && abortController.signal
        };
        const fullResource = overridePath || `${this._resourceName}/${extraPath || ''}`;
        const uri = `${this._fullBaseUri}/${fullResource}`;
        return new Promise<ApiResult<any>>(resolve => {
            fetch(uri, requestOptions)
                .then(r => {
                    switch (r.status) {
                        case 200:
                        case 201:
                            r.json()
                                .then(d => {
                                    resolve({ result: AjaxResult.Success, data: d });
                                })
                                .catch(() => {
                                    resolve({ result: AjaxResult.Success });
                                });
                            break;
                        case 401:
                            resolve({ result: AjaxResult.Unauthorized });
                            this._logout && this._logout();
                            break;
                        case 403:
                            resolve({ result: AjaxResult.Forbidden });
                            this._enqueueSnackbar(
                                `The ${method} request to ${uri} failed because you have insufficient permission to access it`
                            );
                            break;
                        case 409:
                            resolve({ result: AjaxResult.Duplicate });
                            break;
                        case 500:
                        default:
                            r.json().then(d => {
                                if (d && d.errors) {
                                    resolve({ result: AjaxResult.Failure, messages: d.errors });
                                } else {
                                    resolve({ result: AjaxResult.Failure });
                                }
                            });
                            break;
                    }
                })
                .catch(e => {
                    if (e.name === 'AbortError') {
                        resolve({ result: AjaxResult.Cancelled });
                    } else {
                        console.log(e);
                        resolve({ result: AjaxResult.Failure });
                        this._enqueueSnackbar(
                            `Request to ${uri} failed. It's likely the request couldn't reach the server because the server is down`
                        );
                    }
                });
        });
    };
}
