import {
    IDLE_PROMPT_TIMEOUT_SECONDS,
    IDLE_TIMEOUT_FACTOR,
    IDLE_TIMEOUT_MAX_SECONDS,
    LS_KEY_REFRESH_TOKEN_LIFE_SECONDS,
} from "../../../constants";

export function getIdleTimeout(): number {
    const refreshTokenLifeSecondsStr = localStorage.getItem(LS_KEY_REFRESH_TOKEN_LIFE_SECONDS);
    const refreshTokenLifeSeconds = refreshTokenLifeSecondsStr ? parseInt(refreshTokenLifeSecondsStr) : null;
    const idleTimeoutSecondsFactored = refreshTokenLifeSeconds ? refreshTokenLifeSeconds * IDLE_TIMEOUT_FACTOR : null;
    const idleTimeoutSeconds = idleTimeoutSecondsFactored && idleTimeoutSecondsFactored < IDLE_TIMEOUT_MAX_SECONDS
        ? idleTimeoutSecondsFactored
        : IDLE_TIMEOUT_MAX_SECONDS;
    return (idleTimeoutSeconds - IDLE_PROMPT_TIMEOUT_SECONDS) * 1000;
}
