import { useMsal } from "@azure/msal-react";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import {
    LS_KEY_REFRESH_TOKEN_EXP_DATE,
    SSO_AUTHORITIES, SSO_SCOPE,
} from "../../../constants";
import { useTabVisibility } from "../../../hooks/useTabVisibility";
import { AuthState } from "../enums";

import { IMainAuthContext, MainAuthContextProvider, useMainAuth } from "./mainAuthContext";
import { MsalContextProvider } from "./msalContext";

type IAuthContext = {
    logout: () => void;
    changePassword: () => void;
    state: AuthState;
    msalLogin: () => Promise<void>;
} & IMainAuthContext;

const AuthContext = createContext<IAuthContext | null>(null);

function AuthContextProvider({ children }: React.PropsWithChildren<unknown>) {
    const { instance } = useMsal();
    const mainAuth = useMainAuth();
    const { isTabVisible, isTabFocused } = useTabVisibility();

    const [state, setState] = useState(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const stateFromUrl = urlParams.get("state");
        if (stateFromUrl) {
            window.history.replaceState({}, document.title, window.location.pathname);
            return stateFromUrl as AuthState;
        }
        return AuthState.Idle;
    });

    const msalLogin = useCallback(async () => {
        setState(AuthState.MsalRedirectHandle);
        try {
            await instance.initialize();
            const response = await instance.handleRedirectPromise();
            if (response && response.accessToken) {
                setState(AuthState.MainTokenRequest);
                mainAuth.mainLogin(response.accessToken);
            } else {
                setState(AuthState.MsalLogin);
                await instance.loginRedirect({
                    authority: SSO_AUTHORITIES.paznicCustom,
                    scopes: [SSO_SCOPE],
                    prompt: "login",
                    extraQueryParameters: {
                        origin: window.location.origin,
                    },
                });
            }
        } catch (error) {
            console.error(error);
            setState(AuthState.Error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, mainAuth.mainLogin]);

    const changePassword = useCallback(async () => {
        try {
            await instance.loginRedirect({
                authority: SSO_AUTHORITIES.changePassword,
                scopes: [SSO_SCOPE],
                prompt: "login",
                extraQueryParameters: {
                    hint: mainAuth.email || "",
                    origin: window.location.origin,
                },
            });
        } catch (error) {
            console.error("Password change failed:", error);
        }
    }, [instance, mainAuth.email]);

    const logout = useCallback(async () => {
        try {
            mainAuth.mainLogout();
            await instance.logout({
                authority: SSO_AUTHORITIES.paznicCustom,
                state: AuthState.LoggedOut,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setState(AuthState.LoggedOut);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, mainAuth.mainLogout]);

    useEffect(() => {
        setState(prev => mainAuth.loginErrors.length > 0
            ? AuthState.Error
            : prev === AuthState.Error
                ? AuthState.Idle
                : prev);
    }, [mainAuth.loginErrors]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (mainAuth.isAuthenticated) {
            interval = setInterval(() => {
                const expDate = localStorage.getItem(LS_KEY_REFRESH_TOKEN_EXP_DATE);
                if (expDate == null || new Date(expDate) < new Date()) {
                    console.debug('token expired: ' + expDate);
                    logout();
                    clearInterval(interval);
                }
            }, 2000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [logout, mainAuth.isAuthenticated]);

    useEffect(() => {
        if (mainAuth.username || state !== AuthState.Idle || !isTabVisible || !isTabFocused) return;
        msalLogin();
    }, [msalLogin, mainAuth.username, state, isTabVisible, isTabFocused]);

    return (
        <AuthContext.Provider value={{
            ...mainAuth,
            msalLogin,
            logout,
            changePassword,
            state,
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export function FullAuthContextProvider({ children }: React.PropsWithChildren<unknown>) {
    return (
        <MsalContextProvider>
            <MainAuthContextProvider>
                <AuthContextProvider>
                    {children}
                </AuthContextProvider>
            </MainAuthContextProvider>
        </MsalContextProvider>
    );
}


export function useAuth() {
    const value = useContext(AuthContext);

    if (!value) {
        throw new Error("AuthContext consumed outside of AuthContextProvider scope");
    }

    return value;
}
