import { useCallback } from "react";

import {
    LS_KEY_REFRESH_TOKEN_EXP_DATE,
} from "../../../constants";
import { useApi } from "../../../components/useApi";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useAuth } from "../context";
import { useCurrentRefreshToken } from "./useCurrentRefreshToken";

export function useRefreshTokenRequest(): (force?: boolean, expire?: Date) => void {
    const { authApi } = useApi();
    const { logout } = useAuth();
    const [currentRefreshToken, setCurrentRefreshToken] = useCurrentRefreshToken();

    const refreshTokenRequest = useCallback(() => {
        const accessToken = localStorage.getItem("token");

        if (accessToken && currentRefreshToken) {
            authApi.refreshToken(accessToken, currentRefreshToken).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    const { accessToken, refreshToken, refreshTokenExpiration } = r.data;
                    localStorage.setItem("token", accessToken);
                    localStorage.setItem(LS_KEY_REFRESH_TOKEN_EXP_DATE, refreshTokenExpiration);
                    setCurrentRefreshToken(refreshToken);
                    return;
                }
                logout();
            });
        } else {
            logout();
        }
    }, [authApi, currentRefreshToken, logout, setCurrentRefreshToken]);

    return refreshTokenRequest;
}
